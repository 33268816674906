<template>
    <div>
        <v-toolbar dense color="primary" dark>
            <v-toolbar-title>Internamientos</v-toolbar-title>
        </v-toolbar>
        <v-data-table
            elevation="2"
            :headers="headers"
            :items="datos"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loadingDatosTodos"

            :server-items-length='totalRegistros'
            :options.sync="optionsTable"
        >

            <template v-slot:[`item.areaNom`]="{ item }">
                <div>{{item.areaNom}}</div>
                <div>({{item.servicioNom}})</div>
            </template>

            <template v-slot:[`item.veInter`]="{ item }">
                <v-icon @click="$router.push({path:`/hospital/interna/`+item.id})">mdi-folder</v-icon>
            </template>

        </v-data-table>

    </div>
</template>

<script>
export default {
    created(){
        //this.carga()
    },
    props:{
        dp:Object,
        tab: Number,
        esteTab:Number,
    },
    data:()=>({
        params:{},
        headers:[
            {text:'Ingreso', value:'ingreso', width:'15%'},
            {text:'Egreso', value:'egreso', width:'15%'},
            {text:'Motivo', value:'motivo'},
            {text:'Área', value:'areaNom', width:'10%'},
            {text:'', value:'hoja', width:'5%'},
            {text:'', value:'movimientos', width:'5%'},
            {text:'', value:'veInter', width:'5%'},
        ],

        datos:[],
        loadingDatosTodos:false,
        optionsTable:{},
        totalRegistros:0,
    }),

    methods:{
        async carga(){

            //this.params={...this.optionsTable}
            this.params.tipo = 1
            this.params.pacienteId = this.dp.pacienteId
            this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
            this.params.limit=this.optionsTable.itemsPerPage
            this.loadingDatosTodos=true
            try {
                let req = await this.$http({
                    url: '/internamientos/listV2',
                    method:'GET',
                    params: this.params,
                })
                this.loadingDatosTodos=false
                this.datos = req.data.root
                this.totalRegistros = req.data.total
            } catch (err) {
                this.loadingDatosTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

    watch:{
        tab(){
            if(this.tab == this.esteTab){
                this.carga()
            }
        },
        optionsTable: {
            handler () {
                //console.log(this.optionsTable)
                this.carga()
            },
            deep: true,
        },
    },
}
</script>

<style>

</style>